import type { AppProps } from "next/app"
import Head from "next/head"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { useRouter } from "next/router"
import * as Fathom from "fathom-client"

import "styles/globals.css"
import Alert from "alert/Alert"
import { AlertContext } from "alert/useAlert"

const queryClient = new QueryClient()

const App = ({ Component, pageProps }: AppProps) => {
  const [alert, setAlert] = useState<string | undefined>(undefined)
  const router = useRouter()
  useEffect(() => {
    Fathom.load("JLTRGDWO", { includedDomains: ["gradienthub.art"] })
    const onRouteChangeComplete = () => {
      Fathom.trackPageview()
    }

    router.events.on("routeChangeComplete", onRouteChangeComplete)

    return () => {
      router.events.off("routeChangeComplete", onRouteChangeComplete)
    }
  }, [router.events])

  return (
    <>
      <Head>
        <title>GradientHub</title>
        <meta name="description" content="Create and share gradients." />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <QueryClientProvider client={queryClient}>
        <AlertContext.Provider value={{ alert, setAlert }}>
          <div className="text-slate-900 selection:text-white selection:bg-slate-800">
            <Alert />
            <Component {...pageProps} />
          </div>
        </AlertContext.Provider>
      </QueryClientProvider>
    </>
  )
}

export default App
