type Props = {
	className?: string
	children?: React.ReactNode
}

const Acrylic = ({ className, children }: Props) => {
	return (
		<div
			className={[
				"bg-slate-50/20 text-slate-900 backdrop-blur-3xl",
				className,
			].join(" ")}
		>
			{children}
		</div>
	)
}

export default Acrylic
