import { motion, AnimatePresence } from "framer-motion"

import Acrylic from "components/Acrylic"
import useAlert from "./useAlert"

const Alert: React.FC = () => {
	const { alert, setAlert } = useAlert()

	return (
		<section className="z-50 fixed bottom-2 left-0 flex w-full items-center justify-center">
			<AnimatePresence>
				{alert && (
					<motion.span
						initial={{ y: 200 }}
						animate={{
							y: 0,
							transition: { duration: 0.2 },
						}}
						exit={{ y: 200 }}
					>
						<Acrylic className="font-medium p-3 rounded shadow-md">
							{alert}
						</Acrylic>
					</motion.span>
				)}
			</AnimatePresence>
		</section>
	)
}

export default Alert
